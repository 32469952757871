<template>
    <div class="app-page-title ma-0">

        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <v-icon large :class="icon"/>
                </div>
                <div>
                    {{heading}}
                    <div
                            class="page-title-subheading">
                        {{subheading}}
                    </div>
                </div>
            </div>
            <div class="page-title-actions">


                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">

                        <button @click="back" class="btn-shadow d-inline-flex align-items-center btn btn-primary ma-2"
                                type="button" v-if="showPreviousButton" v-on="on">
                            <v-icon class="mr-1 white--text pa-0">arrow_back</v-icon>
                        </button>
                    </template>
                    <span>{{$t('generic.lang_previous')}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">

                        <button @click="edit" class="btn-shadow d-inline-flex align-items-center btn btn-primary pl-1 ma-2"
                                type="button" v-if="showEditButton" v-on="on">
                            <v-icon class="mr-1 white--text pa-0">edit</v-icon>
                            {{$t('erp.lang_ware_edit')}}
                        </button>
                    </template>
                    <span>{{$t('erp.lang_ware_edit')}}</span>
                </v-tooltip>

                <v-tooltip v-if="showAddButton && permissionAdd" bottom>
                    <template v-slot:activator="{ on }">

                        <button @click="add" class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-2"
                                type="button"
                                v-on="on">
                            <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
                            {{$t('generic.lang_create')}}
                        </button>
                    </template>
                    <span>{{$t('generic.lang_add')}}</span>
                </v-tooltip>


                <button @click="dialog = true" class="btn-shadow d-inline-flex align-items-center btn btn-primary ma-2"
                        type="button" v-if="showHelpButton">
                    <v-icon class="mr-1 white--text pa-0" icon="plus">pe-7s-help1</v-icon>
                </button>
            </div>
        </div>


        <v-dialog fullscreen hide-overlay style="z-index: 6000;" transition="dialog-bottom-transition"
                  v-if="showHelpButton"
                  v-model="dialog">
            <v-card>
                <v-toolbar color="primary" dark>

                    <v-toolbar-title class="text-uppercase">{{$t('generic.lang_nav_help')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" dark icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text><br><br>
                  <!--- <helpContent :lang="locale" :route="currentRoute"></helpContent> --->
                </v-card-text>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>

   // import helpContent from "../../components/common/helpContent";
    export default {
        components: {
           // helpContent,
        },
        data() {
            return {
                dialog: false,
                currentRoute: "",
            }
        },
        computed: {
            locale() {
                return this.$i18n.locale;
            }
        },
        props: {
            icon: String,
            heading: String,
            subheading: String,
            showHelpButton: {
                type: Boolean,
                default: false
            },
            permissionAdd: {
                type: Boolean,
                default: false
            },
            showAddButton: {
                type: Boolean,
                default: false
            },
            showPreviousButton: {
                type: Boolean,
                default: false
            },
            showEditButton: {
                type: Boolean,
                default: false
            },
            urlAdd: {
                type: String,
            },
            urlEdit: {
                type: String,
            },
            urlPrevious: {
                type: String,
            }
        },
        mounted() {
            this.currentRoute = this.$router.currentRoute.name;
        },
        methods: {
            add() {


                const url = this.urlAdd != undefined ? this.urlAdd : (this.$route.fullPath + '/create').replace('//', '/')
                //that means that all links should be like (if you want redirection directly to create page without setting props "url-add"): http://localhost:8080/.../group
                //example: http://localhost:8080/erp/storage/storageOverview


                if (url && this.$router.resolve(url).resolved.matched.length > 0)
                    this.$router.push(url);
            },
            edit() {

                const url = this.urlEdit != undefined ? this.urlEdit : this.$route.fullPath.replace('/details/', '/edit/');
                //that means that all links should be like (if you want redirection directly to editing page without setting props "url-add") : localhost/.../details/:id
                //example: http://localhost:8080/erp/storage/storageOverview/details/1

                if (url && this.$router.resolve(url).resolved.matched.length > 0)
                    this.$router.push(url);
            },
            back() {
                if (this.urlPrevious)
                    this.$router.push(this.urlPrevious);
                else this.$router.back();
            }
        }
    }
</script>

<style scoped>
    .v-dialog__content--active {

        z-index: 9999;

    }
</style>